export default function statusReducer(state = "", { type, payload }) {
  switch (type) {
    case "status/loading":
      return payload;
    case "status/succeeded":
      return payload;
    case "status/error":
      return payload;
    default:
      return state;
  }
}
