import userService from "../services/user.service";
const initialState = {
  id_funcionario: { nombre: "", apellido: "", pfp: "", diario: [] },
  _id: "1",
  roles: [],
};
export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "user/getUser":
      return payload;
    case "user/changePfp":
      return {
        ...state,
        id_funcionario: { ...state.id_funcionario, pfp: payload },
      };
    default:
      return state;
  }
}
export function fetchUser(id) {
  return async function fetchOKRThunk(dispatch) {
    const response = await userService.getUser(id);
    dispatch({
      type: "user/getUser",
      payload: {
        ...response.data,
        roles: response.data.roles.map((role) => role.name),
      },
    });
  };
}
