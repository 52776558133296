/* eslint-disable import/no-anonymous-default-export */
import { CHANGE_PASSWORD, GET_FUNCIONARIO, PUT_FUNCIONARIO } from "../types";

const initialState = {
  nombre: "",
  apellido: "",
  cedula: 0,
  fechaNac: "",
  sexo: "",
  calle1: "",
  calle2: "",
  nroCasa: 0,
  ciudad: "",
  pais: "",
  referencia: "",
  tel: "",
  honorario: false,
  aDeclarar: 0,
  cargo: "",
  departamento: "",
  fechaIng: "",
  fechaIngIPS: "",
  fechaSal: "",
  cantHijos: 0,
  montoPorHij: 0,
  diasTrab: 0,
  vacaciones: 0,
  vacacionesTomadas: 0,
  reposo: 0,
  adelSal: "",
  tieneIPS: false,
  IPS: 0,
  bonificaciones: 0,
  banco: "",
  cuenta: "",
  porcentaje: 100,
  aporteMinimo: false,
  regimen: "Mensualero",
  salarioBruto: 0,
  comisiones: 0,
  descuento: 0,
  descuentos: [],
  conceptos: [],
  salarioACobrar: 0,
  jornal: 0,
  cantDias: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "funcionario/edit":
      return {
        ...state,
        [action.payload.target.name]: action.payload.target.value,
      };
    case GET_FUNCIONARIO:
      return action.payload;
    case PUT_FUNCIONARIO:
      return action.payload;
    case CHANGE_PASSWORD:
      return action.payload;
    default:
      return state;
  }
}
